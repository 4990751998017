import AccountTypes from '@/common/enums/accountTypeEnum'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Service Providers',
    route: 'service-providers',
    icon: 'ServerIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Micro Controllers',
    route: 'micro-controllers',
    icon: 'CpuIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
]
